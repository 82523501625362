<template>
  <div>
    <flixLoader v-if="!loaded" />
    <b-flix-calendarpicker
      v-else
      color="#42bd84"
      style="background: transparent; margin: 0"
      :values="Object.keys(nextDates)"
      :value="
        $store.getters.calendarDate
          ? $store.getters.calendarDate.getFullYear() +
            '-' +
            ($store.getters.calendarDate.getMonth() + 1) +
            '-' +
            $store.getters.calendarDate.getDate()
          : false
      "
      :mode="id === '*' ? 'fix' : ''"
      :settings="{
        hover: true,
        shadow: false,
        navigation: true,
        style: 'rounded'
      }"
      @save="getSetDate"
    >
      <div slot="top">
        <calendarSelector :id="id" @save="setID"
          ><span slot="allCalendar"
            >{{ $t('message.allCalendar') }}:
            {{ $tc('message.appointments', 2) }}</span
          ></calendarSelector
        >
        <b-flix-h3 style="color: #42bd84">{{
          $tc('message.nextAppointments', 2)
        }}</b-flix-h3>
      </div>
    </b-flix-calendarpicker>
  </div>
</template>
<script>
export default {
  name: 'NextAppointmentsCalendar',
  components: {
    calendarSelector() {
      return import('@/components/holidays/selector')
    }
  },
  props: {
    filter: [String, Number]
  },
  data() {
    return {
      id: this.getID(),
      nextDates: {},
      slice: 10,
      variables: {},
      loaded: false
    }
  },
  mounted() {
    this.variables = this.$getUserVariables()
    this.getLoadDates()
  },
  methods: {
    getID() {
      if (this.$store.state.assistents.assistentActive) {
        return this.$store.state.assistents.assistentActive.ID
      } else if (typeof this.$store.getters.assistents[0] === 'object') {
        return '*'
      } else {
        return '*'
      }
    },
    setID(id) {
      this.loaded = false
      this.id = id
      this.getLoadDates()
    },
    getSetDate(d) {
      const date = this.$createDate(d.date + ' 00:00:00')
      this.$store.commit('setCalendarDate', date)

      if (d.state === true && this.id !== '*') {
        this.$store.commit('calendar/setCalendarDate', date)
        this.$router.push({
          name: 'dashboardAddCustomer',
          params: { id: this.id }
        })
        return false
      }

      var name = 'bookingCalendarDate'
      const params = {
        date:
          date.getFullYear() +
          '-' +
          this.$getNullsBefore(date.getMonth() + 1) +
          '-' +
          this.$getNullsBefore(date.getDate())
      }

      if (
        typeof this.$store.state.assistents.assistentActive === 'object' &&
        typeof this.$store.state.assistents.assistentActive.ID !==
          'undefined' &&
        this.$store.state.assistents.assistentActive.ID !== '*'
      ) {
        name = 'specificBookingCalendar'
        params.id = this.$store.state.assistents.assistentActive.ID
      }

      if (this.variables.state === 'share') {
        name = 'sharesBookingCalendarDate'
      }

      this.$router.push({ name, params })
    },
    getLoadDates() {
      var data = {
        user: this.variables.user.md5_id
      }
      if (typeof this.id === 'string' && this.id !== '*') {
        data.ID = this.id
      }
      this.$flix_post({
        url: 'booking/next',
        data: data,
        callback: function (ret) {
          this.nextDates = {}
          if (ret.data[0]) {
            Object.keys(ret.data[1]).forEach(
              function (k) {
                this.nextDates[k] = this.$createDate(k)
              }.bind(this)
            )
          }
          this.loaded = true
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped></style>
