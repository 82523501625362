var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loaded)?_c('flixLoader'):_c('b-flix-calendarpicker',{staticStyle:{"background":"transparent","margin":"0"},attrs:{"color":"#42bd84","values":Object.keys(_vm.nextDates),"value":_vm.$store.getters.calendarDate
        ? _vm.$store.getters.calendarDate.getFullYear() +
          '-' +
          (_vm.$store.getters.calendarDate.getMonth() + 1) +
          '-' +
          _vm.$store.getters.calendarDate.getDate()
        : false,"mode":_vm.id === '*' ? 'fix' : '',"settings":{
      hover: true,
      shadow: false,
      navigation: true,
      style: 'rounded'
    }},on:{"save":_vm.getSetDate}},[_c('div',{attrs:{"slot":"top"},slot:"top"},[_c('calendarSelector',{attrs:{"id":_vm.id},on:{"save":_vm.setID}},[_c('span',{attrs:{"slot":"allCalendar"},slot:"allCalendar"},[_vm._v(_vm._s(_vm.$t('message.allCalendar'))+": "+_vm._s(_vm.$tc('message.appointments', 2)))])]),_c('b-flix-h3',{staticStyle:{"color":"#42bd84"}},[_vm._v(_vm._s(_vm.$tc('message.nextAppointments', 2)))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }